[dir="rtl"] .mr-2,
[dir="rtl"] .mx-2 {
  margin: 0 !important;
}

[dir="rtl"] .p-1 {
  margin-left: 0.5em;
}

[dir="rtl"] .mr-2 {
  margin-left: 0.5rem !important;
}

[dir="rtl"] .pl-2,
[dir="rtl"] .px-2 {
  padding: 0 !important;
}

[dir="rtl"] .pl-2 {
  padding-right: 0.5em !important;
}

[dir="rtl"] .ml-3,
[dir="rtl"] .mx-3 {
  margin: 0 !important;
}

[dir="rtl"] .ml-2,
[dir="rtl"] .mx-2 {
  margin: 0 !important;
}

[dir="rtl"] .ml-2 {
  margin-right: 0.5rem !important;
}

[dir="rtl"] .ml-3 {
  margin-right: 1rem !important;
}

[dir="rtl"] .mr-3,
[dir="rtl"] .mx-3 {
  margin: 0 !important;
}

[dir="rtl"] .mr-3 {
  margin-left: 0.5rem !important;
}

[dir="rtl"] .mr-4,
[dir="rtl"] .mx-4 {
  margin: 0 !important;
}

[dir="rtl"] .mr-4,
[dir="rtl"] .mx-4 {
  margin-left: 1.5rem !important;
}

[dir="rtl"] .mt-2,
[dir="rtl"] .my-2 {
  margin-top: 0.5rem !important;
}

[dir="rtl"] .ml-auto,
[dir="rtl"] .mx-auto {
  margin: 0;
}

[dir="rtl"] .ml-auto {
  margin-right: auto !important;
}

[dir="rtl"] .mr-0,
[dir="rtl"] .mx-0 {
  margin-right: 0 !important;
}

[dir="rtl"] .ml-0,
[dir="rtl"] .mx-0 {
  margin-left: 0 !important;
}

[dir="rtl"] .mr-1,
[dir="rtl"] .mx-1 {
  margin-right: 0.25rem !important;
}

[dir="rtl"] .ml-1,
[dir="rtl"] .mx-1 {
  margin-left: 0.25rem !important;
}

[dir="rtl"] .mr-2,
[dir="rtl"] .mx-2 {
  margin-right: 0.5rem !important;
}

[dir="rtl"] .ml-2,
[dir="rtl"] .mx-2 {
  margin-left: 0.5rem !important;
}

[dir="rtl"] .mr-3,
[dir="rtl"] .mx-3 {
  margin-right: 1rem !important;
}

[dir="rtl"] .ml-3,
[dir="rtl"] .mx-3 {
  margin-left: 1rem !important;
}

[dir="rtl"] .mr-4,
[dir="rtl"] .mx-4 {
  margin-right: 1.5rem !important;
}

[dir="rtl"] .ml-4,
[dir="rtl"] .mx-4 {
  margin-left: 1.5rem !important;
}

[dir="rtl"] .mr-5,
[dir="rtl"] .mx-5 {
  margin-right: 3rem !important;
}

[dir="rtl"] .ml-5,
[dir="rtl"] .mx-5 {
  margin-left: 3rem !important;
}

[dir="rtl"] .pr-0,
[dir="rtl"] .px-0 {
  padding-right: 0 !important;
}

[dir="rtl"] .pl-0,
[dir="rtl"] .px-0 {
  padding-left: 0 !important;
}

[dir="rtl"] .pr-1,
[dir="rtl"] .px-1 {
  padding-right: 0.25rem !important;
}

[dir="rtl"] .pl-1,
[dir="rtl"] .px-1 {
  padding-left: 0.25rem !important;
}

[dir="rtl"] .pr-2,
[dir="rtl"] .px-2 {
  padding-right: 0.5rem !important;
}

[dir="rtl"] .pl-2,
[dir="rtl"] .px-2 {
  padding-left: 0.5rem !important;
}

[dir="rtl"] .pr-3,
[dir="rtl"] .px-3 {
  padding-right: 1rem !important;
}

[dir="rtl"] .pl-3,
[dir="rtl"] .px-3 {
  padding-left: 1rem !important;
}

[dir="rtl"] .pr-4,
[dir="rtl"] .px-4 {
  padding-right: 1.5rem !important;
}

[dir="rtl"] .pl-4,
[dir="rtl"] .px-4 {
  padding-left: 1.5rem !important;
}

[dir="rtl"] .pr-5,
[dir="rtl"] .px-5 {
  padding-right: 3rem !important;
}

[dir="rtl"] .pl-5,
[dir="rtl"] .px-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  [dir="rtl"] .mr-sm-0,
  [dir="rtl"] .mx-sm-0 {
    margin-right: 0 !important;
  }

  [dir="rtl"] .ml-sm-0,
  [dir="rtl"] .mx-sm-0 {
    margin-left: 0 !important;
  }

  [dir="rtl"] .mr-sm-1,
  [dir="rtl"] .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  [dir="rtl"] .ml-sm-1,
  [dir="rtl"] .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  [dir="rtl"] .mr-sm-2,
  [dir="rtl"] .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  [dir="rtl"] .ml-sm-2,
  [dir="rtl"] .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  [dir="rtl"] .mr-sm-3,
  [dir="rtl"] .mx-sm-3 {
    margin-right: 1rem !important;
  }

  [dir="rtl"] .ml-sm-3,
  [dir="rtl"] .mx-sm-3 {
    margin-left: 1rem !important;
  }

  [dir="rtl"] .mr-sm-4,
  [dir="rtl"] .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  [dir="rtl"] .ml-sm-4,
  [dir="rtl"] .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  [dir="rtl"] .mr-sm-5,
  [dir="rtl"] .mx-sm-5 {
    margin-right: 3rem !important;
  }

  [dir="rtl"] .ml-sm-5,
  [dir="rtl"] .mx-sm-5 {
    margin-left: 3rem !important;
  }

  [dir="rtl"] .pr-sm-0,
  [dir="rtl"] .px-sm-0 {
    padding-right: 0 !important;
  }

  [dir="rtl"] .pl-sm-0,
  [dir="rtl"] .px-sm-0 {
    padding-left: 0 !important;
  }

  [dir="rtl"] .pr-sm-1,
  [dir="rtl"] .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  [dir="rtl"] .pl-sm-1,
  [dir="rtl"] .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  [dir="rtl"] .pr-sm-2,
  [dir="rtl"] .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  [dir="rtl"] .pl-sm-2,
  [dir="rtl"] .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  [dir="rtl"] .pr-sm-3,
  [dir="rtl"] .px-sm-3 {
    padding-right: 1rem !important;
  }

  [dir="rtl"] .pl-sm-3,
  [dir="rtl"] .px-sm-3 {
    padding-left: 1rem !important;
  }

  [dir="rtl"] .pr-sm-4,
  [dir="rtl"] .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  [dir="rtl"] .pl-sm-4,
  [dir="rtl"] .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  [dir="rtl"] .pr-sm-5,
  [dir="rtl"] .px-sm-5 {
    padding-right: 3rem !important;
  }

  [dir="rtl"] .pl-sm-5,
  [dir="rtl"] .px-sm-5 {
    padding-left: 3rem !important;
  }
}

@media (min-width: 768px) {
  [dir="rtl"] .mr-md-0,
  [dir="rtl"] .mx-md-0 {
    margin-right: 0 !important;
  }

  [dir="rtl"] .ml-md-0,
  [dir="rtl"] .mx-md-0 {
    margin-left: 0 !important;
  }

  [dir="rtl"] .mr-md-1,
  [dir="rtl"] .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  [dir="rtl"] .ml-md-1,
  [dir="rtl"] .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  [dir="rtl"] .mr-md-2,
  [dir="rtl"] .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  [dir="rtl"] .ml-md-2,
  [dir="rtl"] .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  [dir="rtl"] .mr-md-3,
  [dir="rtl"] .mx-md-3 {
    margin-right: 1rem !important;
  }

  [dir="rtl"] .ml-md-3,
  [dir="rtl"] .mx-md-3 {
    margin-left: 1rem !important;
  }

  [dir="rtl"] .mr-md-4,
  [dir="rtl"] .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  [dir="rtl"] .ml-md-4,
  [dir="rtl"] .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  [dir="rtl"] .mr-md-5,
  [dir="rtl"] .mx-md-5 {
    margin-right: 3rem !important;
  }

  [dir="rtl"] .ml-md-5,
  [dir="rtl"] .mx-md-5 {
    margin-left: 3rem !important;
  }

  [dir="rtl"] .pr-md-0,
  [dir="rtl"] .px-md-0 {
    padding-right: 0 !important;
  }

  [dir="rtl"] .pl-md-0,
  [dir="rtl"] .px-md-0 {
    padding-left: 0 !important;
  }

  [dir="rtl"] .pr-md-1,
  [dir="rtl"] .px-md-1 {
    padding-right: 0.25rem !important;
  }

  [dir="rtl"] .pl-md-1,
  [dir="rtl"] .px-md-1 {
    padding-left: 0.25rem !important;
  }

  [dir="rtl"] .pr-md-2,
  [dir="rtl"] .px-md-2 {
    padding-right: 0.5rem !important;
  }

  [dir="rtl"] .pl-md-2,
  [dir="rtl"] .px-md-2 {
    padding-left: 0.5rem !important;
  }

  [dir="rtl"] .pr-md-3,
  [dir="rtl"] .px-md-3 {
    padding-right: 1rem !important;
  }

  [dir="rtl"] .pl-md-3,
  [dir="rtl"] .px-md-3 {
    padding-left: 1rem !important;
  }

  [dir="rtl"] .pr-md-4,
  [dir="rtl"] .px-md-4 {
    padding-right: 1.5rem !important;
  }

  [dir="rtl"] .pl-md-4,
  [dir="rtl"] .px-md-4 {
    padding-left: 1.5rem !important;
  }

  [dir="rtl"] .pr-md-5,
  [dir="rtl"] .px-md-5 {
    padding-right: 3rem !important;
  }

  [dir="rtl"] .pl-md-5,
  [dir="rtl"] .px-md-5 {
    padding-left: 3rem !important;
  }
}

@media (min-width: 992px) {
  [dir="rtl"] .mr-lg-0,
  [dir="rtl"] .mx-lg-0 {
    margin-right: 0 !important;
  }

  [dir="rtl"] .ml-lg-0,
  [dir="rtl"] .mx-lg-0 {
    margin-left: 0 !important;
  }

  [dir="rtl"] .mr-lg-1,
  [dir="rtl"] .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  [dir="rtl"] .ml-lg-1,
  [dir="rtl"] .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  [dir="rtl"] .mr-lg-2,
  [dir="rtl"] .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  [dir="rtl"] .ml-lg-2,
  [dir="rtl"] .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  [dir="rtl"] .mr-lg-3,
  [dir="rtl"] .mx-lg-3 {
    margin-right: 1rem !important;
  }

  [dir="rtl"] .ml-lg-3,
  [dir="rtl"] .mx-lg-3 {
    margin-left: 1rem !important;
  }

  [dir="rtl"] .mr-lg-4,
  [dir="rtl"] .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  [dir="rtl"] .ml-lg-4,
  [dir="rtl"] .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  [dir="rtl"] .mr-lg-5,
  [dir="rtl"] .mx-lg-5 {
    margin-right: 3rem !important;
  }

  [dir="rtl"] .ml-lg-5,
  [dir="rtl"] .mx-lg-5 {
    margin-left: 3rem !important;
  }

  [dir="rtl"] .pr-lg-0,
  [dir="rtl"] .px-lg-0 {
    padding-right: 0 !important;
  }

  [dir="rtl"] .pl-lg-0,
  [dir="rtl"] .px-lg-0 {
    padding-left: 0 !important;
  }

  [dir="rtl"] .pr-lg-1,
  [dir="rtl"] .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  [dir="rtl"] .pl-lg-1,
  [dir="rtl"] .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  [dir="rtl"] .pr-lg-2,
  [dir="rtl"] .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  [dir="rtl"] .pl-lg-2,
  [dir="rtl"] .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  [dir="rtl"] .pr-lg-3,
  [dir="rtl"] .px-lg-3 {
    padding-right: 1rem !important;
  }

  [dir="rtl"] .pl-lg-3,
  [dir="rtl"] .px-lg-3 {
    padding-left: 1rem !important;
  }

  [dir="rtl"] .pr-lg-4,
  [dir="rtl"] .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  [dir="rtl"] .pl-lg-4,
  [dir="rtl"] .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  [dir="rtl"] .pr-lg-5,
  [dir="rtl"] .px-lg-5 {
    padding-right: 3rem !important;
  }

  [dir="rtl"] .pl-lg-5,
  [dir="rtl"] .px-lg-5 {
    padding-left: 3rem !important;
  }
}

@media (min-width: 1200px) {
  [dir="rtl"] .mr-xl-0,
  [dir="rtl"] .mx-xl-0 {
    margin-right: 0 !important;
  }

  [dir="rtl"] .ml-xl-0,
  [dir="rtl"] .mx-xl-0 {
    margin-left: 0 !important;
  }

  [dir="rtl"] .mr-xl-1,
  [dir="rtl"] .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  [dir="rtl"] .ml-xl-1,
  [dir="rtl"] .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  [dir="rtl"] .mr-xl-2,
  [dir="rtl"] .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  [dir="rtl"] .ml-xl-2,
  [dir="rtl"] .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  [dir="rtl"] .mr-xl-3,
  [dir="rtl"] .mx-xl-3 {
    margin-right: 1rem !important;
  }

  [dir="rtl"] .ml-xl-3,
  [dir="rtl"] .mx-xl-3 {
    margin-left: 1rem !important;
  }

  [dir="rtl"] .mr-xl-4,
  [dir="rtl"] .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  [dir="rtl"] .ml-xl-4,
  [dir="rtl"] .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  [dir="rtl"] .mr-xl-5,
  [dir="rtl"] .mx-xl-5 {
    margin-right: 3rem !important;
  }

  [dir="rtl"] .ml-xl-5,
  [dir="rtl"] .mx-xl-5 {
    margin-left: 3rem !important;
  }

  [dir="rtl"] .pr-xl-0,
  [dir="rtl"] .px-xl-0 {
    padding-right: 0 !important;
  }

  [dir="rtl"] .pl-xl-0,
  [dir="rtl"] .px-xl-0 {
    padding-left: 0 !important;
  }

  [dir="rtl"] .pr-xl-1,
  [dir="rtl"] .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  [dir="rtl"] .pl-xl-1,
  [dir="rtl"] .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  [dir="rtl"] .pr-xl-2,
  [dir="rtl"] .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  [dir="rtl"] .pl-xl-2,
  [dir="rtl"] .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  [dir="rtl"] .pr-xl-3,
  [dir="rtl"] .px-xl-3 {
    padding-right: 1rem !important;
  }

  [dir="rtl"] .pl-xl-3,
  [dir="rtl"] .px-xl-3 {
    padding-left: 1rem !important;
  }

  [dir="rtl"] .pr-xl-4,
  [dir="rtl"] .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  [dir="rtl"] .pl-xl-4,
  [dir="rtl"] .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  [dir="rtl"] .pr-xl-5,
  [dir="rtl"] .px-xl-5 {
    padding-right: 3rem !important;
  }

  [dir="rtl"] .pl-xl-5,
  [dir="rtl"] .px-xl-5 {
    padding-left: 3rem !important;
  }
}
