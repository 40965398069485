:root {
  --iq-primary: #FFBD59 ;
  --iq-primary-hover: #00ff4079;
  --iq-light-primary: #d7d3f8;
  --iq-secondary: #6c757d;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-title-text: #23203c;
  --iq-body-text: #d1d0cf;
  --iq-body-bg: #141414;
  --iq-bg1: #191919;
  --iq-body-border: #393750;
  --iq-gold: #e6c75f;
  --iq-black: #000000;
  --iq-form-gradient-color: rgba(11, 1, 2, 0);
  --iq-to-gradient-color: rgba(255, 55, 65, 0.3);
}
