/*================================================
[  Table of contents  ]
================================================

:: Import CSS
:: Font
:: General
:: Input
:: Loading
:: Background Color
:: Background Color Opacity
:: Border Color
:: Buttons Design
:: Text Color
:: Line Height
:: Font Size
:: Font Weight
:: Avatar Size
:: Tab Pills
:: Overflow
:: Custom Column
:: Select 2

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
Import CSS
-----------------------------------------------------------------------*/
@import url("variable.css");
@import url("all.min.css");
@import url("remixicon.css");
@import url("slick.css");
@import url("slick-theme.css");
@import url("owl.carousel.min.css");
@import url("animate.min.css");
@import url("magnific-popup.css");
@import url("select2.min.css");
@import url("select2-bootstrap4.min.css");
@import url("dark.css");
@import url("slick-animation.css");
@import url("typography-rtl.css");
@import url("spacer.css");
@import url("frontend-style.css");
@import url("swiper.css");

/*---------------------------------------------------------------------
Font
-----------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

@font-face {
  font-family: "Helvetica Neue Regular";
  src: url(../fonts/helvetica/HelveticaNeueBold.woff) format("woff");
}

/*---------------------------------------------------------------------
General
-----------------------------------------------------------------------*/
*::-moz-selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

::selection {
  background: var(--iq-primary);
  color: var(--iq-white);
  text-shadow: none;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  color: var(--iq-body-text);
  background: var(--iq-body-bg);
}

a,
.btn {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

a,
button,
input,
btn {
  outline: medium none;
}

.table thead th {
  border-bottom: 2px solid #141414;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #141414;
}

.table {
  color: #d1d0cf;
}

a {
  color: var(--iq-body-text);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a.text-primary:focus,
a.text-primary:hover {
  color: var(--iq-primary) !important;
}

hr {
  border-color: var(--iq-border-light);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0px;
  line-height: 1.2;
  color: var(--iq-white);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

label {
  font-weight: normal;
}

h1,
.h1 {
  font-size: 3.052em;
}

h2,
.h2 {
  font-size: 2.3em;
}

h3,
.h3 {
  font-size: 1.953em;
}

h4,
.h4 {
  font-size: 1.4em;
}

h5,
.h5 {
  font-size: 1.2em;
}

h6,
.h6 {
  font-size: 1em;
}

.container-fluid {
  padding: 0 100px;
}

.height-100-vh {
  height: 100vh;
}

.w-20 {
  width: 20px;
}

.big-title {
  font-family: "Helvetica Neue Regular";
}

.badge {
  border-radius: 0 !important;
}

.iq-mb-30 {
  margin-bottom: 30px;
}

/*----------------------------------------------
Input
------------------------------------------------*/
label {
  color: var(--iq-dark);
}

.form-control {
  height: 45px;
  line-height: 45px;
  background: transparent;
  border: 1px solid var(--iq-body-text);
  font-size: 14px;
  color: var(--iq-secondary);
  border-radius: 0;
}

.iq-custom-select {
  position: relative;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--iq-primary) !important;
  border-color: var(--iq-primary) !important;
}

dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0px;
}

/*----------------------------------------------
loading
------------------------------------------------*/
#loading {
  height: 100%;
  width: 100%;
  background-size: 15%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

/*----------------------------------------------
Background Color
------------------------------------------------*/
.bg-primary,
.badge-primary {
  color: var(--iq-white) !important;
  background: var(--iq-primary) !important;
}

.bg-body,
.badge-body {
  color: var(--iq-body-text);
  background: var(--iq-body-bg);
}

.bg-box,
.badge-box {
  color: var(--iq-white);
  background: var(--iq-bg-box);
}

/*----------------------------------------------
Background Color Opacity
------------------------------------------------*/
.iq-bg-primary {
  background: var(--iq-light-primary) !important;
  color: var(--iq-primary) !important;
}

/*----------------------------------------------
Border Color
------------------------------------------------*/
.iq-body-border {
  border: 1px solid var(--iq-body-border);
}

/*----------------------------------------------
 Buttons Design
------------------------------------------------*/
.btn {
  padding: 9px 30px;
  position: relative;
  display: inline-block;
  -webkit-border-radius: 0p;
  -moz-border-radius: 0;
  border-radius: 5px;
  cursor: pointer;
  z-index: 4;
}

.btn-hover {
  background: var(--iq-primary-hover);
  border: 1px solid var(--iq-primary-hover);
  color: var(--iq-white);
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

}

.btn-hover:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--iq-primary);
  border: 1px solid var(--iq-primary);
  border-radius: 6px;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition: transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out;
}

.btn-hover:hover,
.btn-hover:focus,
.btn-hover:active {
  color: var(--iq-white);
}

.btn-hover:focus {
  box-shadow: none !important;
}

.btn-hover:hover:before,
.btn-hover:focus:before,
.btn-hover:active:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.btn-outline-primary {
  background: transparent;
  border: 2px solid var(--iq-primary);
  color: var(--iq-primary);
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.btn-outline-primary:hover {
  background: var(--iq-primary-hover) !important;
  border: 2px solid var(--iq-primary-hover);
  color: var(--iq-white);
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: var(--iq-primary);
  border-color: var(--iq-primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: var(--iq-primary);
  background: transparent;
  border-color: var(--iq-primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none;
}

button:focus {
  outline: none;
}

.btn-link {
  background: transparent;
  color: var(--iq-white);
}

.btn-link:hover {
  color: var(--iq-primary);
  text-decoration: none;
}

/*----------------------------------------------
Text Color
------------------------------------------------*/
.text-body {
  color: var(--iq-body-text) !important;
}

.text-primary {
  color: var(--iq-primary) !important;
}

.text-white {
  color: var(--iq-white) !important;
}

.text-black {
  color: var(--iq-black) !important;
}

.text-gold {
  color: var(--iq-gold) !important;
}

/*----------------------------------------------
Line Height
------------------------------------------------*/
.line-height {
  line-height: normal;
}

.line-height-2 {
  line-height: 2;
}

.line-height-4 {
  line-height: 40px;
}

.line-height-6 {
  line-height: 60px;
}

/*----------------------------------------------
Font Size
------------------------------------------------*/
.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-40 {
  font-size: 40px;
}

/*----------------------------------------------
Font Weight
------------------------------------------------*/
.font-Weight-300 {
  font-weight: 300;
}

.font-Weight-500 {
  font-weight: 500;
}

.font-Weight-600 {
  font-weight: 600;
}

.font-Weight-700 {
  font-weight: 700;
}

/*----------------------------------------------
Avatar Size
------------------------------------------------*/
.avatar-3 {
  height: 3px;
  width: 3px;
  min-width: 3px;
  line-height: 3px;
}

.avatar-7 {
  height: 7px;
  width: 7px;
  min-width: 4px;
  line-height: 7px;
}

.avatar-20 {
  height: 20px;
  width: 20px;
  min-width: 20px;
  line-height: 20px;
}

.avatar-30 {
  height: 30px;
  width: 30px;
  min-width: 30px;
  line-height: 30px;
  font-size: 0.4rem;
}

.avatar-35 {
  height: 35px;
  width: 35px;
  min-width: 35px;
  line-height: 35px;
  font-size: 0.5rem;
}

.avatar-40 {
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  font-size: 0.6rem;
}

.avatar-45 {
  height: 45px;
  width: 45px;
  min-width: 45px;
  line-height: 45px;
  font-size: 0.8rem;
}

.avatar-50 {
  height: 50px;
  width: 50px;
  min-width: 50px;
  line-height: 50px;
  font-size: 1rem;
}

.avatar-60 {
  height: 60px;
  width: 60px;
  min-width: 60px;
  line-height: 60px;
  font-size: 1.2rem;
}

.avatar-70 {
  height: 70px;
  width: 70px;
  min-width: 70px;
  line-height: 70px;
  font-size: 1.4rem;
}

.avatar-80 {
  height: 80px;
  width: 80px;
  min-width: 80px;
  line-height: 80px;
  font-size: 1.6rem;
}

.avatar-90 {
  height: 90px;
  width: 90px;
  min-width: 90px;
  line-height: 90px;
  font-size: 1.6rem;
}

.avatar-100 {
  height: 100px;
  width: 100px;
  min-width: 100px;
  line-height: 100px;
  font-size: 1.6rem;
}

.avatar-110 {
  height: 110px;
  width: 110px;
  min-width: 110px;
  line-height: 110px;
  font-size: 1.6rem;
}

.avatar-120 {
  height: 120px;
  width: 120px;
  min-width: 120px;
  line-height: 120px;
  font-size: 1.6rem;
}

.avatar-130 {
  height: 130px;
  width: 130px;
  min-width: 130px;
  line-height: 130px;
  font-size: 1.6rem;
}

/*----------------------------------------------
Tab Pills
------------------------------------------------*/
.nav-pills .nav-item a {
  color: var(--iq-body-text);
  border-radius: 50px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--iq-white);
  background: transparent;
}

.nav-pills .nav-link:hover {
  color: var(--iq-primary);
}

.nav-tabs {
  border-bottom: 2px solid var(--iq-light-primary);
  margin-bottom: 15px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .nav-item a {
  color: var(--iq-body-text);
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid var(--iq-primary);
  color: var(--iq-primary);
}

/*----------------------------------------------
Overflow
------------------------------------------------*/
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-scroll-x {
  overflow-x: scroll;
}

.overflow-scroll-y {
  overflow-y: scroll;
}

/*----------------------------------------------
Custom Column
------------------------------------------------*/
.col-1-5 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/*----------------------------------------------
Select 2
------------------------------------------------*/
.iq-custom-select .select2-container--bootstrap4 .select2-selection,
.select2-dropdown {
  background-color: var(--iq-bg1);
  border: none;
  border-radius: 0;
  color: #fff;
}

.select2-dropdown {
  top: 0;
  box-shadow: 0 12px 34px 0 rgba(19, 10, 10, 1);
}

.iq-custom-select select {
  box-shadow: none;
}

.select2-container--bootstrap4
  .select2-dropdown
  .select2-results__option[aria-selected="true"] {
  background-color: var(--iq-body-bg);
}

.select2-container--bootstrap4 .select2-results__option--highlighted,
.select2-container--bootstrap4
  .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
  background-color: var(--iq-primary);
  color: var(--iq-white);
}

.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: calc(1.5em + 1.2rem);
  padding-left: 10px;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  box-shadow: none;
}

.select2-search--dropdown .select2-search__field {
  border-radius: 0;
  border: none;
  background: rgba(85, 85, 85, 0.65);
  color: var(--iq-white);
}

.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  border: none;
}

.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b:before {
  content: "\ea41";
  position: absolute;
  font-family: "remixicon";
  top: 50%;
  transform: translateY(-50%);
  right: 1px;
  color: var(--iq-white);
  font-size: 16px;
  z-index: 99;
  transition: all 0.4s ease;
}

.select2-container--bootstrap4.select2-container--open
  .select2-selection__arrow
  b:before {
  transform: translateY(-50%) rotate(180deg);
}

.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 0;
}

.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow {
  width: auto;
}

.select2-container--bootstrap4 .select2-selection--single,
.select2-container--bootstrap4 .select2-selection--multiple {
  height: 45px !important;
}

.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
  border-radius: 0;
  border: none;
}

.sea-epi .select2-container {
  width: 150px !important;
}
